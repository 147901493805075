import axios from '@/plugins/axios'

export default {
  async products() {
    return axios.get(`/seller/loan/products`)
  },
  async lenders(query = '') {
    return axios.get(`/seller/loan/lenders${query}`)
  },
  async lenderDetail(bankId, debiturId) {
    return axios.get(`/seller/loan/lenders/${bankId}/${debiturId}`)
  },
  async simulate(payload) {
    return axios.post(`/seller/loan/simulate`, payload)
  },
  async register(payload) {
    return axios.post(`/seller/loan/register`, payload)
  },
  async submission(payload) {
    return axios.post(`/seller/loan/submission`, payload)
  },
}