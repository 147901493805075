import axios from 'axios'
import store from '@/store'
import router from '@/router'

const baseURL = process.env.VUE_APP_BASE_API
export const baseImage = process.env.VUE_APP_BASE_STATIC

const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    // Accept: "application/json",
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// Request interceptor
instance.interceptors.request.use(
  request => {
    if (!ignoreLoading.includes(request.url)) {
      store.commit('setLoading', true)
    }

    return request
  },
  error => {
    store.commit('setLoading', false)

    return error
  }
)
const ignoreMessages = [
  '/seller/register',
  '/seller/login',
  '/seller/logout',
  '/broadcasting/auth',
]

const ignoreLoading = [
  '/seller/notifications',
  '/seller/notifications/:id/read',
  '/seller/notifications/refresh',
]

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    store.commit('setLoading', false)
    if (response.config.method != 'get' && !ignoreMessages.includes(response.config.url)) {
      if (!ignoreLoading.includes(response.config.url)) {
        store.dispatch('public/notify',
          { status: 'success', text: 'Data Berhasil diproses', message: response.data.message }
        )
      }
    }

    return response
  },
  (error) => {
    store.commit('setLoading', false)
    const { status, data } = error.response;

    if (status == 401) {
      store.dispatch('auth/setUser', null)
    } else if (status == 503){
      router.push({ name: 'maintenance' })
    } else {
      if (! ignoreLoading.includes(error.config.url)) {
        store.dispatch('public/notify',
          { status: 'error', text: 'Oopss! Error ' + status, message: data.message }
        )
      }
    }

    return Promise.reject(error)
  }
)

export default instance
