import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import './assets/css/style.css'
import './assets/css/global.css'

Vue.config.productionTip = false

Vue.use(Vuelidate)

Vue.filter('formatPrice', function (value) {
  let val = (value / 1).toFixed(0).replace(".", ",");
  return "Rp" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
})

Vue.filter('formatNumber', function (value) {
  let val = (value / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
})

Vue.filter('formatDate', (value, full = true) => {
  const formattedDate = new Date(value).toLocaleDateString('id-ID', { dateStyle: 'long' })
  const formattedTime = new Date(value).toLocaleTimeString('id-ID',
    { hour: '2-digit', minute: '2-digit', hour12: false }
  )

  return `${formattedDate}${full ? `, ${formattedTime} WIB` : ''}`
});

Vue.directive(
  'clicked-outside', {
    bind: function(el, binding, vNode) {
      // Provided expression must evaluate to a function.
      if (typeof binding.value !== 'function') {
        const compName = vNode.context.name
        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
        if (compName) { warn += `Found in component '${compName}'` }
        
        console.warn(warn)
      }
      // Define Handler and cache it on the element
      const bubble = binding.modifiers.bubble
      const handler = (e) => {
        if (bubble || (!el.contains(e.target) && el !== e.target)) {
          binding.value(e)
        }
      }
      el.__vueClickOutside__ = handler

      // add Event Listeners
      document.addEventListener('click', handler)
    },
    
    unbind: function(el, binding) {
      // Remove Event Listeners
      document.removeEventListener('click', el.__vueClickOutside__)
      el.__vueClickOutside__ = null

    }
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
